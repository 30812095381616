<template>
  <div id="app">
    <router-view v-wechat-title="$route.meta.title"></router-view>
  </div>
</template>

<script>
import cadreWxUserService from "@/api/cadrewxuser/cadreWxUserService";
import common from "@/utils/common";
import wx from "weixin-jsapi";
export default {
  name: "App",
  data() {
    return {
      user: null,
      openId:null,
      code:null,
    };
  },
  mounted() {
  },
  created() {
  },
  methods:{

  },
};
</script>

<style></style>
