import request from '@/utils/httpRequest'

export default {
  teacherList  (params) {
    return request({
      url: '/wx/wxInfo/teacherList',
      method: 'get',
      params: params
    })
  },
  teacherDetail  (params) {
    return request({
      url: '/wx/wxInfo/teacherDetail',
      method: 'get',
      params: params
    })
  },
  getOpenId(code){
    return request({
      url: '/wx/wxInfo/getOpenId',
      method: 'get',
      params: {code: code}
    })
  },
  getCode(phone){
    return request({
      url: '/wx/wxInfo/getCode',
      method: 'get',
      params: {phone:phone}
    })
  },
  authenticationUser(inputForm){
    return request({
      url: '/wx/wxInfo/authenticationUser',
      method: 'post',
      data: inputForm
    })
  },
  userInfo  (params) {
    return request({
      url: '/wx/wxInfo/userInfo',
      method: 'get',
      params: params
    })
  },
  campusInfo  (params) {
    return request({
      url: '/wx/wxInfo/campusInfo',
      method: 'get',
      params: params
    })
  },
  getConfig(url){
    return request({
      url: '/wx/wxInfo/getConfig',
      method: 'get',
      params: {url:url}
    })
  },


}
