import request from '@/utils/httpRequest'

export default class DictService {
  getDictMap () {
    return request({
      url: '/wx/dict/getDictMap',
      method: 'get',
      params: {}
    })
  }
}
