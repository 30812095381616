/* eslint-disable */
import WxUserService from "@/api/cadrewxuser/WxUserService";
import DictService from "@/api/sys/DictService";
import common from "./common";

export default {
    // 获取
    login() {
        // 清空storage
        localStorage.removeItem("userList")
        localStorage.removeItem("userId")
        localStorage.removeItem("user")
        localStorage.removeItem("status") // 未注册 用户状态：0已登录，1已注册未登录， 2未注册
        console.log("自动登录前"+localStorage.getItem('openId'))
        let wxUserService = new WxUserService()
        return wxUserService.wxUserList({
            'wxAccount': localStorage.getItem('openId')
        }).then(res => {
            if (common.isEmpty(res.data)) {
                localStorage.setItem("status", "2") // 2未注册
                console.log("not find user info")
            } else {
                // 获取的绑定用户列表不为空 -> 放入localStorage
                localStorage.setItem("userList", JSON.stringify(res.data))
                localStorage.setItem("status", "1") // 1已注册未登录
                // 当前显示的user
                res.data.map(user => {
                    if (user.status === '1') {
                        console.log(user)
                        localStorage.setItem("userId", user.cadreUserId)
                        localStorage.setItem("status", "0") // 已登录
                        localStorage.setItem("user", JSON.stringify(user.cadreUser))
                    }
                })
                // console.log(localStorage.getItem("userList"))
                // console.log(localStorage.getItem("userId"))
                this.getDict()
            }
        }).catch(err => {
            return err
        })
    },
    // 获取字典集
    getDict() {
        let dictService = new DictService()
        dictService.getDictMap().then(res => {
            localStorage.setItem("dictList", JSON.stringify(res.data))
        }).catch(err => {
            console.log(err)
        })
    }

}
