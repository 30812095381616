import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router/index.js'
import '@/assets/css/mystyle.css'
import VueWechatTitle from 'vue-wechat-title' //可以动态修改浏览器标题的插件
import autoLogin from "@/utils/autoLogin"
import { Toast } from 'vant'
Vue.use(Toast);
Vue.use(VueWechatTitle);
Vue.use(Vant);
Vue.config.productionTip = false
Vue.prototype.$autoLogin = autoLogin

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')