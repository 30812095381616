import request from '@/utils/httpRequest'

export default class WxUserService {
  wxUserList  (params) {
    return request({
      url: '/wx/wxInfo/wxUserList',
      method: 'get',
      params: params
    })
  }
  getUser  (id) {
    return request({
      url: '/wx/wxInfo/getUser',
      method: 'get',
      params: {id: id}
    })
  }

  toLoginStudent(inputForm){
    return request({
      url: '/wx/wxInfo/toLoginStudent',
      method: 'post',
      data: inputForm
    })
  }
  toCreatStudent(inputForm){
    return request({
      url: '/wx/wxInfo/toCreatStudent',
      method: 'post',
      data: inputForm
    })
  }
  toLogoutStudent(wxAccount){
    return request({
      url: '/wx/wxInfo/toLogoutStudent',
      method: 'get',
      params: {wxAccount: wxAccount}
    })
  }
  toChangeUser(data){
    return request({
      url: '/wx/wxInfo/toChangeUser',
      method: 'get',
      params: data
    })
  }
}
