import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
    routes: [{
            path: '/',
            name: "indexTab",
            component: () => import("../view/indexTab.vue"),
            redirect: "/index",
            children: [{
                path: '/index',
                name: "index",
                component: () => import("../view/student/index.vue"),
                meta: {
                    title: "石嘴山老年大学"
                },
            }, {
                path: '/course',
                name: "course",
                component: () => import("../view/student/course.vue"),
                meta: {
                    title: "课程"
                },
            }, {
                path: '/activity',
                name: "activity",
                component: () => import("../view/student/activity.vue"),
                meta: {
                    title: "活动"
                },
            }, {
                path: '/notice',
                name: "notice",
                component: () => import("../view/student/notice.vue"),
                meta: {
                    title: "通知"
                },
            }, {
                path: '/wo',
                name: "wo",
                component: () => import("../view/student/wo.vue"),
                meta: {
                    title: "我的"
                },
            }]
        },
        {
            path: '/lgbIndex',
            name: "lgbIndex",
            component: () => import("../view/lgb-bureau/lgbIndex.vue"),
            meta: {
                title: "石嘴山老干部局"
            },
        },
        {
            path: '/noticeDetail',
            name: "noticeDetail",
            component: () => import("../view/student/notice/noticeDetail.vue"),
            meta: {
                title: "通知"
            },
        }, {
            path: '/register',
            name: "register",
            component: () => import("../view/login/register.vue"),
            meta: {
                title: "注册"
            },
        }, {
            path: '/forgetPass',
            name: "forgetPass",
            component: () => import("../view/login/forgetPass.vue"),
            meta: {
                title: "忘记密码"
            },
        }, {
            path: '/login',
            name: "login",
            component: () => import("../view/login/login.vue"),
            meta: {
                title: "登录"
            },
        }, {
            path: '/switchAccount',
            name: "switchAccount",
            component: () => import("../view/login/switchAccount.vue"),
            meta: {
                title: "切换账号"
            },
        }, {
            path: '/schoolIntro',
            name: "schoolIntro",
            component: () => import("../view/student/home/schoolIntro.vue"),
            meta: {
                title: "学校介绍"
            },
        }, {
            path: '/teachingStaff',
            name: "teachingStaff",
            component: () => import("../view/student/home/teachingStaff.vue"),
            meta: {
                title: "师资队伍"
            },
        }, {
            path: '/techDetail',
            name: "techDetail",
            component: () => import("../view/student/home/techDetail.vue"),
            meta: {
                title: "教师介绍"
            },
        }, {
            path: '/articleList',
            name: "articleList",
            component: () => import("../view/student/home/articleList.vue"),
            meta: {
                title: "文章列表"
            },
        }, {
            path: '/schoolActivite',
            name: "schoolActivite",
            component: () => import("../view/student/home/schoolActivite.vue"),
            meta: {
                title: "课程介绍"
            },
        }, {
            path: '/Detail',
            name: "Detail",
            component: () => import("../view/student/home/Detail.vue"),
            meta: {
                title: "动态详情"
            },
        }, {
            path: '/studentStory',
            name: "studentStory",
            component: () => import("../view/student/home/studentStory.vue"),
            meta: {
                title: "学员故事"
            },
        }, {
            path: '/agedCare',
            name: "agedCare",
            component: () => import("../view/student/home/agedCare.vue"),
            meta: {
                title: "规章制度"
            },
        }, {
            path: '/regisProcess',
            name: "regisProcess",
            component: () => import("../view/student/home/regisProcess.vue"),
            meta: {
                title: "学员风采"
            },
        }, {
            path: '/realName',
            name: "realName",
            component: () => import("../view/student/wo/realName.vue"),
            meta: {
                title: "实名认证"
            },
        }, {
            path: '/activitySchedule',
            name: "activitySchedule",
            component: () => import("../view/student/home/activitySchedule.vue"),
            meta: {
                title: "活动安排"
            },
        }, {
            path: '/courseSchedule',
            name: "courseSchedule",
            component: () => import("../view/student/home/courseSchedule.vue"),
            meta: {
                title: "课程安排"
            },
        }, {
            path: '/noticeGg',
            name: "noticeGg",
            component: () => import("../view/student/home/noticeGg.vue"),
            meta: {
                title: "通知公告"
            },
        },{
            path: '/articleTzgg',
            name: "articleTzgg",
            component: () => import("../view/articleTzgg.vue"),
            meta: {
                title: "通知公告"
            },
        }, {
            path: '/regisProcessDetail',
            name: "regisProcessDetail",
            component: () => import("../view/student/home/regisProcessDetail.vue"),
            meta: {
                title: "流程详情"
            },
        }, {
            path: '/courseDetail',
            name: "courseDetail",
            component: () => import("../view/student/course/courseDetail.vue"),
            meta: {
                title: "课程详情"
            },
        }, {
            path: '/activityDetail',
            name: "activityDetail",
            component: () => import("../view/student/activity/activityDetail.vue"),
            meta: {
                title: "活动详情"
            },
        }, {
            path: '/woCourse',
            name: "woCourse",
            component: () => import("../view/student/wo/woCourse.vue"),
            meta: {
                title: "我的课程"
            },
        }, {
            path: '/woCourseDetail',
            name: "woCourseDetail",
            component: () => import("../view/student/wo/woCourseDetail.vue"),
            meta: {
                title: "课程详情"
            },
        },{
            path: '/woCourseDetailWait',
            name: "woCourseDetailWait",
            component: () => import("../view/student/wo/woCourseDetailWait.vue"),
            meta: {
                title: "课程详情"
            },
        }, {
            path: '/woActivity',
            name: "woActivity",
            component: () => import("../view/student/wo/woActivity.vue"),
            meta: {
                title: "我的活动"
            },
        }, {
            path: '/attendanceLlist',
            name: "attendanceLlist",
            component: () => import("../view/student/wo/attendanceLlist.vue"),
            meta: {
                title: "考勤列表"
            },
        }, {
            path: '/woInformation',
            name: "woInformation",
            component: () => import("../view/student/wo/woInformation.vue"),
            meta: {
                title: "个人资料"
            },
        }, {
            path: '/integralDetail',
            name: "integralDetail",
            component: () => import("../view/student/wo/integralDetail.vue"),
            meta: {
                title: "积分明细"
            },
        }, {
            path: '/settingUp',
            name: "settingUp",
            component: () => import("../view/student/wo/settingUp.vue"),
            meta: {
                title: "设置"
            },
        }, {
            path: '/activityAttend',
            name: "activityAttend",
            component: () => import("../view/student/wo/activityAttend.vue"),
            meta: {
                title: "活动考勤记录"
            },
        }, {
            path: '/courseAttend',
            name: "courseAttend",
            component: () => import("../view/student/wo/courseAttend.vue"),
            meta: {
                title: "课程考勤记录"
            },
        }, {
            path: '/editPhone',
            name: "editPhone",
            component: () => import("../view/student/wo/editPhone.vue"),
            meta: {
                title: "修改手机号"
            },
        }, {
            path: '/editPassword',
            name: "editPassword",
            component: () => import("../view/student/wo/editPassword.vue"),
            meta: {
                title: "修改密码"
            },
        }, {
            path: '/woInform',
            name: "woInform",
            component: () => import("../view/student/wo/woInform.vue"),
            meta: {
                title: "个人信息"
            },
        }, {
            path: '/woInformEdit',
            name: "woInformEdit",
            component: () => import("../view/student/wo/woInformEdit.vue"),
            meta: {
                title: "编辑个人信息"
            },
        }, {
            path: '/search',
            name: "search",
            component: () => import("../view/student/wo/search.vue"),
            meta: {
                title: "搜索"
            },
        }, {
            path: '/newsList',
            name: "newsList",
            component: () => import("../view/lgb-bureau/newsList.vue"),
            meta: {
                title: "更多"
            },
        },
        {
            path: '/newsDetail',
            name: "newsDetail",
            component: () => import("../view/lgb-bureau/newsDetail.vue"),
            meta: {
                title: "详情"
            },
        }, {
            path: '/teacher',
            name: "teacher",
            component: () => import("../view/teacher/teacher.vue"),
            meta: {
                title: "教师中心"
            },
        }, {
            path: '/teacherCourse',
            name: "teacherCourse",
            component: () => import("../view/teacher/teacherCourse.vue"),
            meta: {
                title: "我的课程"
            },
        }, {
            path: '/teacherCourseDetail',
            name: "teacherCourseDetail",
            component: () => import("../view/teacher/teacherCourseDetail.vue"),
            meta: {
                title: "课程详情"
            },
        }, {
            path: '/teacherAttendanceLlist',
            name: "teacherAttendanceLlist",
            component: () => import("../view/teacher/teacherAttendanceLlist.vue"),
            meta: {
                title: "考勤列表"
            },
        }, {
            path: '/teacherInformation',
            name: "teacherInformation",
            component: () => import("../view/teacher/teacherInformation.vue"),
            meta: {
                title: "个人资料"
            },
        }, {
            path: '/teacherSettingUp',
            name: "teacherSettingUp",
            component: () => import("../view/teacher/settingUp.vue"),
            meta: {
                title: "设置"
            },
        }, {
            path: '/teacherCourseAttend',
            name: "teacherCourseAttend",
            component: () => import("../view/teacher/teacherCourseAttend.vue"),
            meta: {
                title: "课程考勤记录"
            },
        }, {
            path: '/teacherEditPhone',
            name: "teacherEditPhone",
            component: () => import("../view/teacher/teacherEditPhone.vue"),
            meta: {
                title: "修改手机号"
            },
        }, {
            path: '/teacherEditPassword',
            name: "teacherEditPassword",
            component: () => import("../view/teacher/teacherEditPassword.vue"),
            meta: {
                title: "修改密码"
            },
        }, {
            path: '/teacherInform',
            name: "teacherInform",
            component: () => import("../view/teacher/teacherInform.vue"),
            meta: {
                title: "个人信息"
            },
        }, {
            path: '/teacherInformEdit',
            name: "teacherInformEdit",
            component: () => import("../view/teacher/teacherInformEdit.vue"),
            meta: {
                title: "编辑个人信息"
            },
        }, {
            path: '/teacherNotice',
            name: "teacherNotice",
            component: () => import("../view/teacher/teacherNotice.vue"),
            meta: {
                title: "通知"
            },
        }
    ]
})
router.beforeEach((to, from, next) => {
    const title = to.meta.title;
    if (title) {
        document.title = title;
    }
    next();
});
const originalPush = VueRouter.prototype.push

export default router;